import { useContext } from 'react';
import BaseDataContext from './baseData/BaseDataContext';

export const useUtilityFunctions = () => {
    const context = useContext(BaseDataContext);

    if (!context) {
        throw new Error('useAsyncFunctions must be used within AsyncFunctionsProvider');
    }

    return context;
};