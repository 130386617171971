import React, { useState } from 'react';
import { ModalDialog, ThemeBuilder, availableCSSAttributes } from '@tbirdcomponents/reactcomponents'; // Adjust the import path accordingly
import { useUtilityFunctions } from '../../hooks/UtilityFunctionsHook'; // Adjust the import path accordingly
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";

import { nanoid } from "nanoid";


const selectorColumns = [
    { field: "name", Header: "Name", Type: "Text" },
    { field: "type", Header: "Type", Type: "Text" },
];

const pageSize = [2, 5, 10, 20, 50, 100];

const defaultTheme = {
    "id": nanoid(),
    "name": "",
    "selectors": []
}

const ThemeGrid = ({ themesList }) => {

    const { Authorization, FileProcessing } = useUtilityFunctions();
    const [workingThemes, setworkingThemes] = useState(themesList);
    const [currentTheme, setCurrentTheme] = useState();
    const [themeFileName, setThemeFileName] = useState();
    const [newThemeName, setNewThemeName] = useState();
    const [showModal, setShowModal] = useState(false);
    const [selectors, setSelectors] = useState();

    const generateColumns = () => {
        const columnDefinitions = selectorColumns.map(column => {
            let columnDef = {
                field: column.field,
                headerName: column.Header,
                flex: 1.5,
                type: column.Type,
            };
            if (column.field === "type") {
                columnDef.renderCell = (params) => {
                    return params.row.type ? params.row.type.label : "";
                };
            }
            return columnDef;
        });

        columnDefinitions.push({
            field: "delete",
            headerName: "",
            flex: 1,
            renderCell: (params) => (
                <Button
                    color="error"
                    onClick={(event) => {
                        event.stopPropagation(); // Prevent event from bubbling up
                        handleDelete(params.row.id);
                    }}
                >
                    Delete
                </Button>
            ),
        });

        return columnDefinitions;
    };

    const handleDelete = async (themeId) => {
        // Find the theme to be deleted by its id
        const themeToDelete = workingThemes.find(theme => theme.id === themeId);
        if (!themeToDelete) {
            console.error("Theme not found");
            return;
        }
        const folderPath = 'websites/' + Authorization.getConfiguration().Site.siteName + '/themes'; // Call the deleteFileData function
        const fileName = themeToDelete.name + '.css'; // Assuming the file name is based on the theme name
        const result = await FileProcessing.deleteFileData(folderPath, fileName);
        if (result) {
            console.log('File deleted successfully:', fileName);
            setworkingThemes(workingThemes.filter(theme => theme.id !== themeId));// Remove the theme from the workingThemes state
        }
    };
    const handleSaveSelectors = async (data) => {
        let cssContent = '';

        // Loop through each key in the data object
        Object.keys(data).forEach(key => {
            const selector = data[key];
            let cssSelector = '';

            // Determine the CSS selector type (class, id, tag)
            switch (selector.type) {
                case 'class':
                    cssSelector = `.${selector.name}`;
                    break;
                case 'Id':
                    cssSelector = `#${selector.name}`;
                    break;
                case 'tag':
                    cssSelector = selector.name;
                    break;
                default:
                    cssSelector = selector.name;
                    break;
            }

            // Construct CSS rules for each styling element
            let cssRules = selector.stylingElements.map(element => {
                return `\t${element.attName}: ${element.attValue};`;
            }).join('\n');

            // Combine the selector and the rules
            cssContent += `${cssSelector} {\n${cssRules}\n}\n`;
        });

        console.log(cssContent);
        //saveToFile('path/to/folder', 'style.css', cssContent, 'text/css'); Authorization.getConfiguration().Site.siteName
        const config = Authorization.getConfiguration();
        console.log(config);
        const folderPath = 'websites/' + config.Site.siteName + '/themes'; // Specify the folder path
        const fileName = themeFileName + '.css'; // Specify the file name
        try {
            const result = await FileProcessing.saveFileData(folderPath, fileName, cssContent, 'text/css');
            if (result) {
                console.log('File saved successfully:', result);
            }
        } catch (error) {
            console.error('Error saving file:', error);
        }
        setCurrentTheme(null);
    };


    const handleGetTheme = async (fileName) => {
        const config = Authorization.getConfiguration();
        const folderPath = 'websites/' + config.Site.siteName + '/themes';

        // Ensure the fileName ends with '.css'
        const fileNameWithExtension = fileName.endsWith('.css') ? fileName : `${fileName}.css`;

        try {
            const result = await FileProcessing.getFileData(folderPath, fileNameWithExtension);
            if (result) {
                console.log('Theme retrieved successfully:', result);

                // Transform CSS content to JavaScript object
                const themeObject = parseCssToThemeObject(result, availableCSSAttributes);
                console.log(themeObject);
                setSelectors(themeObject);
            }
        } catch (error) {
            console.error('Themes retrieval failed:', error);
        }
    };


    // Function to parse CSS content and transform it into a JavaScript object
    function parseCssToThemeObject(cssContent, availableCSSAttributes) {
        const themeObject = [];
        const cssBlocks = cssContent.split('}').filter(Boolean); // Split CSS content into blocks

        cssBlocks.forEach((block, index) => {
            const [selectorText, rulesText] = block.split('{').map(s => s.trim());
            if (!selectorText || !rulesText) return;

            const selectorType = determineSelectorType(selectorText);
            const selector = {
                id: index + 1,
                name: selectorText.replace(/\.|#/, ''), // Remove '.' or '#' from selector names
                type: selectorType,
                stylingElements: []
            };

            const rules = rulesText.split(';').filter(Boolean); // Split rules text into individual rules
            rules.forEach((rule, ruleIndex) => {
                let [attName, attValue] = rule.split(':').map(s => s.trim());
                attName = attName.toLowerCase(); // Convert attName to lowercase

                if (attName && attValue) {
                    const attributeDetails = availableCSSAttributes.find(attr => attr.name === attName);
                    const attType = attributeDetails ? { label: attributeDetails.label, value: attributeDetails.type } : { label: 'Generic', value: 'generic' };

                    selector.stylingElements.push({
                        id: ruleIndex + 1,
                        attName,
                        attValue,
                        attType,
                    });
                }
            });
            console.log(selector);
            themeObject.push(selector);
        });

        return themeObject;
    }

    function determineSelectorType(selectorText) {
        if (selectorText.startsWith('.')) {
            return { label: 'Class', value: 'class' };
        } else if (selectorText.startsWith('#')) {
            return { label: 'Id', value: 'id' };
        } else {
            return { label: 'Tag', value: 'tag' }; // Default to 'tag' for other cases
        }
    }

    const handleRowClick = async (selectionModel) => {
        if (selectionModel.row === currentTheme) {
            setCurrentTheme(null);
            setThemeFileName(null);
            setShowModal(false);

        } else {
            await handleGetTheme(selectionModel.row.name)
            setThemeFileName(selectionModel.row.name);
            setCurrentTheme(selectionModel.row);
            setShowModal(true);
        }
    };

    const handleNewTheme = () => {
        const newTheme = {
            ...defaultTheme,
            name: newThemeName,
            id: newThemeName,
        };
        setCurrentTheme(newTheme); // Set the new theme as the current theme
        setworkingThemes([...workingThemes, newTheme]); // Optional: Add the new theme to the working themes
        setThemeFileName(newTheme.name)
        setShowModal(true);
    };
    const handleNameChange = (e) => {
        setNewThemeName(e.target.value); // Update the new theme name as the input changes
    }
    return (
        <>
            <h3>Themes</h3>
            <DataGrid
                sx={{ "--unstable_DataGrid-radius": "0" }}
                rows={workingThemes == null ? [] : workingThemes}
                columns={generateColumns()}
                initialState={{}}
                rowHeight={54}
                onRowClick={handleRowClick}
                pageSizeOptions={pageSize??[]}
            />
            <TextField
                type="text"
                id="name-standard-basic"
                label="Name"
                variant="standard"
                value={newThemeName}
                onChange={handleNameChange} 
            />
            <Button onClick={handleNewTheme}>Create</Button>

            {currentTheme &&
                <ModalDialog open={showModal} onClose={() => { setShowModal(false); }}>            
                <div style={{ minWidth: '900px', minheight: '600px' }}>
                        <ThemeBuilder columns={selectorColumns} pageSize={pageSize} selectors={selectors} onSaveSelectors={handleSaveSelectors}></ThemeBuilder>
                </div>
            </ModalDialog>}
        </>
    );

}
export default ThemeGrid;