import React, { useEffect, useState } from 'react';
import { useUtilityFunctions } from '../../hooks/UtilityFunctionsHook'; // Adjust the import path accordingly
import { NestedDynamicMenu } from '@tbirdcomponents/reactcomponents'; // Adjust the import path accordingly
import HtmlContentRenderer from './HtmlContentRenderer'; // Adjust the import path accordingly
import { HtmlEditor } from '@tbirdcomponents/reactcomponents'; // Adjust the import path accordingly
import MenuEditor from './MenuEditor'
import ArticleEditor from './ArticleEditor'

import EditIcon from '@mui/icons-material/Edit';
import './PageContainer.css';
function PageContainer({ editMenu,pageModel,imageList,config }) {

    const { FileProcessing, WebSiteState, DatabaseProcessing } = useUtilityFunctions();
    const [editHeader, setEditHeader] = useState(false);
    //read main menu file test commit
    const [menuObject, setMenuObject] = useState(null); // State to track if config is loaded.
    const [headerHTML, setHeaderHTML] = useState(null); // State to track if config is loaded.
    const [dynamicCSS, setDynamicCSS] = useState('');
    const [articles, setArticles] = useState(null);


    const columns = [
        { field: "name", Header: "Name", Type: "Text" },
        { field: "description", Header: "Description", Type: "Text" },
    ];

    const handleSaveHeaderHtml = async (htlmContent) => {
        const folder = 'websites/' + config.Site.siteName;
        await FileProcessing.saveFileData(folder, config.Site.headerFileName, htlmContent, 'text/html');
        setHeaderHTML(htlmContent);
    };

    const handleSaveMenu = async (menuItems) => {
        const rootPath = 'websites/' + config.Site.siteName;
        await FileProcessing.saveFileData(rootPath, config.Site.menuConfigFileName, JSON.stringify(menuItems));
        setMenuObject(menuItems);
    };


    const handleContentChanged = (htlmContent) => {
        setHeaderHTML(htlmContent);
    };

    const handleOnSearch = async (criteria) => {
        console.log(criteria);
        const pageInfo = await DatabaseProcessing.searchPage(criteria);
        console.log(pageInfo);
        return pageInfo;
    };

    //Load Initial Menu data from the file
    useEffect(() => {
        const getPageInfo = async () => {
            // Early return if siteInfo is null or undefined

            const rootPath = `websites/${config.Site.siteName}`;
            const headerInfo = await FileProcessing.getFileData(rootPath, config.Site.headerFileName);
            const menuInfo = await FileProcessing.getFileDataObject(rootPath, config.Site.menuConfigFileName);

            setMenuObject(menuInfo);
            setHeaderHTML(headerInfo);
        }

        getPageInfo();
    }, [FileProcessing, config]);

    //Load Theme Information based upon Page: TODO bind to Page
    useEffect(() => {
        // Define a function to fetch and set the CSS
        const loadDynamicCSS = async () => {
            // Early return if siteInfo is null or undefined
            const rootPath = `websites/${config.Site.siteName}`;
            const siteCSS = await FileProcessing.getFileData(rootPath, config.Site.themeFileName);
            setDynamicCSS(siteCSS);
        };

        // Call the function
        loadDynamicCSS();
    }, [FileProcessing, config]);


    //Dynamically bind style element to head for render... 
    useEffect(() => {
        // Create a style element
        const style = document.createElement('style');
        style.textContent = dynamicCSS;

        // Append style element to the head
        document.head.appendChild(style);

        // Cleanup the effect when the component unmounts
        return () => {
            // Directly use the created style element for removal
            if (document.head.contains(style)) {
                document.head.removeChild(style);
            }
        };
    }, [dynamicCSS]); // Re-run this effect if the dynamicCSS state changes


    //Load Page and Render - Initial page based upon settings config.initialPage TODO: adjust to use Database based upon page name and then articles... 
    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const rootPath = `websites/${config.Site.siteName}`;
                if (pageModel) {
                    //load all articles from the page data...
                    const articlesWithHTML = await Promise.all(pageModel.articles.map(async (item) => {
                        try {
                            if (item.articlePath) {
                                const articleHTML = await FileProcessing.getFileData(`${rootPath}/articles`, item.articlePath);
                                return { ...item, articleHTML }; // Return the updated item.
                            }
                        } catch (articleError) {
                            console.error("Failed to fetch article HTML:", articleError);
                            return { ...item, articleHTML: '' }; // Return item with empty HTML in case of error
                        }
                    }));
                    setArticles(articlesWithHTML);
                }
            } catch (error) {
                console.error("Failed to fetch articles:", error);
                // Handle error state as needed
            }
        };

        fetchArticles();
    }, [ FileProcessing, config, pageModel]);

    const onImageUploaded = async (blobInfo, success, failure) => {
        const folder = 'assets/' + config.Site.siteName + '/images';
        const fileData = blobInfo.blob();

        //saveFileData: async (folder, fileName, fileData, contentType)
        await FileProcessing.saveFileData(folder, blobInfo.filename(), fileData, fileData.type);
        var fullFilename = config.Site.imageURL + 'public/' + folder + '/' + blobInfo.filename();
        success(fullFilename);
        return fullFilename;
    };
    const handleEditMode = () => {
        setEditHeader(!editHeader);
    };
    return (
        <div className='pageContents'>
            {WebSiteState.isLoggedIn() && <div style={{ float: 'right' }}>
                <EditIcon onClick={handleEditMode} />
            </div>}
            <div className='headerContents'>
                {headerHTML && !editHeader && <HtmlContentRenderer htmlContent={headerHTML} />}
                {headerHTML && editHeader && <HtmlEditor initialHtml={headerHTML} onSave={handleSaveHeaderHtml} onContentChanged={handleContentChanged} imagesUploadHandler={onImageUploaded} images={imageList}> </HtmlEditor>}
            </div>
            <div className='menuContents'>
                {menuObject && !editMenu && <NestedDynamicMenu menuItems={menuObject} />}
                {menuObject && editMenu && <MenuEditor menuItems={menuObject} dataGridColumns={columns} onSearch={handleOnSearch} onSaveChanges={handleSaveMenu}></MenuEditor>}
            </div>
            <div className='articleContents'>
                {articles?.map((article) => (
                    <ArticleEditor key={article.id} article={article} siteInfo={config} images={imageList} onImageUploaded={onImageUploaded} />
                ))}
            </div>
        </div>
    );
}

export default PageContainer;